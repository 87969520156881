import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

// Hooks
import { useBindAPI } from '../hooks/useBindAPI';
import { useAuthContext } from '../hooks/useAuthContext';

const TransferPage = () => {

	const { loggedInUser } = useAuthContext();

	const [currency, setCurrency] = useState('');
	const [amount, setAmount] = useState(0);
	const [receiver, setReceiver] = useState('');

	const { bindAPI, isLoading, error } = useBindAPI();

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
	  const response = await fetch('https://api.booster.my/user/'+loggedInUser.user.username, {
		method: 'GET',
		headers: {
		  'Content-Type': 'application/json',
		  'Authorization': `Bearer ${loggedInUser.token}`
		}
	  });
	  const json = await response.json();
	  if (response.ok) {
		if (json.length === 1) {
		  // Expect only 1 result
		  console.log(json[0]);
		  setUserData(json[0]);
		} else {
		  console.log("Invalid user data: More than 1 result.");
		}
	  }
	};

	const handleSubmit = async (e) => {
        e.preventDefault();
		// await bindAPI(loggedInUser.token, apiKey, apiSecret);
		fetchUserData();
    };

	useEffect(() => {
		if (loggedInUser) {
		  fetchUserData();
		}
	  }, [loggedInUser]);

	return (
		<section className="section-preset negative-margin">
			<div className="container">
				<div className="row">

					<div className="col-12 p-1">
						<h4 className="text-white"><strong>Transfer Balance</strong></h4>
						<div className="data-box-white">
							<form className="w-100" onSubmit={handleSubmit}>
								
								<h6 className="brand-primary"><strong>Choose Transfer Currency:</strong></h6>
								<div className="mb-3">
									<input
										type="text"
										placeholder='Choose Currency'
										autoComplete='off'
										name='currency'
										className='form-control'
										onChange={(e) => setCurrency(e.target.value)}
									/>
								</div>
								<h6 className="text-gray mb-0"><strong>Available Balance</strong></h6>
								<p className="balance-text">{userData && parseFloat(userData.cashWallet).toFixed(2)} <span className="text-gray" style={{fontSize:"16px"}}>AUSD</span></p>
								
								<h6 className="brand-primary"><strong>Transfer Amount:</strong></h6>
								<div className="mb-3">
									<input
										type="number"
										placeholder='Enter Amount'
										autoComplete='off'
										name='amount'
										className='form-control'
										onChange={(e) => setAmount(e.target.value)}
									/>
								</div>
								
								<h6 className="brand-primary"><strong>Transfer to (Receiver):</strong></h6>
								<div className="mb-3">
									<input
										type="text"
										placeholder='Enter Receiver Username'
										name='password'
										className='form-control rounded-0'
										onChange={(e) => setReceiver(e.target.value)}
									/>
								</div>
								<button disabled={currency == '' || amount == 0 || receiver == ''} type="submit" className="btn main-button mt-3 w-100">
									<p className="text-white mb-0"><strong>Transfer</strong></p>
								</button>
								{error && <div className="error">{error}</div>}
							</form>
						</div>
					</div>

				</div>
			</div>
		</section>
	);

};

export default TransferPage;