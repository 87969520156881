import { useState } from 'react';

export const useBindAPI = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const bindAPI = async (userAuthToken, userKey, userSecret) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch('https://api.booster.my/bybitapi/user/bind-api', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userAuthToken}`
            },
			body: JSON.stringify({
                apiKey: userKey,
                apiSecret: userSecret
            })
        })
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
			setError(json.error);
		}
		if (response.ok) {
            setIsLoading(false);
		}
    }

    return { bindAPI, isLoading, error };
}