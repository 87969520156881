import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import store from "./redux/store";
import { Provider } from "react-redux";
import { AuthContextProvider } from './context/authContext';
import { UserContextProvider } from './context/userContext';
import "./assets/bootstrap/css/bootstrap.min.css";
import "./assets/css/theme.css";
import "./assets/css/style.css";

ReactDOM.render(
  <AuthContextProvider>
    <UserContextProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </UserContextProvider>
  </AuthContextProvider>,
  document.getElementById("root")  
);