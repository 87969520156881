import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';

// Hooks
import { useSignup } from '../hooks/useSignup';

const Register = () => {

	const queryParameters = new URLSearchParams(window.location.search);
	var referrerWallet = queryParameters.get("referral");

	const [username, setUsername] = useState('');
	const [referrer, setReferrer] = useState('');
	const [password, setPassword] = useState('');

	const { signup, isLoading, error } = useSignup();

	const handleSubmit = async (e) => {
        e.preventDefault();
		if (referrer == '') {
			// console.log("master");
			if (referrerWallet == null || referrerWallet == '') {
				await signup(username, password, "default");
			} else {
				await signup(username, password, referrerWallet);
			}

		} else {
			// console.log(referrer);
			await signup(username, password, referrer);
		}
    };

	useEffect(() => {
		console.log(referrerWallet);
	}, []);

	return (
		<section className="section-preset negative-margin">
			<div className="container form-page-padding">
				<div className="row">
					<div className="col-12 p-1">
						<div className="data-box-white">
							<form className="w-100" onSubmit={handleSubmit}>
								<h5 className="brand-primary"><strong>Register Account</strong></h5>
								<div className="mb-2">
									<input
										required
										type="text"
										placeholder='Username'
										autoComplete='off'
										name='username'
										className='form-control'
										onChange={(e) => setUsername(e.target.value)}
									/>
								</div>
								<div className="mb-2">
									<input
										required
										type="password"
										placeholder='Password'
										name='password'
										className='form-control rounded-0'
										onChange={(e) => setPassword(e.target.value)}
									/>
								</div>
								<h5 className="brand-primary mt-3"><strong>Referral Program</strong></h5>
								<div className="mb-2">
									<input
										type="text"
										placeholder='Referrer (Optional)'
										autoComplete='off'
										name='referrer'
										className='form-control'
										defaultValue={referrerWallet ? referrerWallet : ''}
										onChange={(e) => setReferrer(e.target.value)}
									/>
								</div>
								<button disabled={username == '' || password == ''} type="submit" className="btn main-button mt-3 w-100">
									<p className="text-white mb-0"><strong>Register</strong></p>
								</button>
								{error && <div className="error">{error}</div>}
							</form>
							<NavLink to="/login">
								<p className="mt-3 mb-2">Already a member?</p>
							</NavLink>
						</div>
					</div>
				</div>
			</div>
		</section>
	);

};

export default Register;