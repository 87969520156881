import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

// Hooks
import { useAuthContext } from '../hooks/useAuthContext';

const MembershipPage = () => {

	const { loggedInUser } = useAuthContext();

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
	  const response = await fetch('https://api.booster.my/user/'+loggedInUser.user.username, {
		method: 'GET',
		headers: {
		  'Content-Type': 'application/json',
		  'Authorization': `Bearer ${loggedInUser.token}`
		}
	  });
	  const json = await response.json();
	  if (response.ok) {
		if (json.length === 1) {
		  // Expect only 1 result
		  console.log(json[0]);
		  setUserData(json[0]);
		} else {
		  console.log("Invalid user data: More than 1 result.");
		}
	  }
	};

	const handleSubmit = async (e) => {
        e.preventDefault();

		fetchUserData();
    };

	useEffect(() => {
		if (loggedInUser) {
		  fetchUserData();
		}
	  }, [loggedInUser]);

	return (
		<section className="section-preset negative-margin">
			<div className="container">
				<div className="row">

					<div className="col-12 p-1">
						<h4 className="text-white"><strong>Membership</strong></h4>
						<div className="data-box-white silver-card">
							<h5 className="silver-vip mb-0"><i className="fa-sharp fa-solid fa-crown mr-2"></i>Silver Member</h5>
						</div>
					</div>

					<div className="col-12 p-1">
                        <div className="data-box-white">
                          <h6 className="text-gray mb-0"><strong>Available Balance</strong></h6>
                          <p className="balance-text mb-0">{userData && parseFloat(userData.cashWallet).toFixed(2)} <span className="text-gray" style={{fontSize:"16px"}}>AUSD</span></p>
                          <button className="btn action-button mt-2" onClick={(e) => {
                          }}>
                            <p className="mb-0" style={{fontSize:"14px"}}><strong>+ Top-up</strong></p>
                          </button>
                        </div>
					</div>

					<div className="col-12 p-1">
                        <div className="data-box-white">
                          <h6 className="text-gray mb-0"><strong>1 AUSD = 4.15 eMYR</strong></h6>
                          <p className="balance-text mb-0">{userData && parseFloat(userData.cashWallet).toFixed(2)} <span className="text-gray" style={{fontSize:"16px"}}>eMYR</span></p>
                          <button disabled className="btn action-button mt-2" onClick={(e) => {
                          }}>
                            <p className="mb-0" style={{fontSize:"14px"}}><strong>Exchange</strong></p>
                          </button>
                        </div>
					</div>

					<div className="col-12 p-1 mt-5">
						<h6 className=""><strong>My Merchant Collection</strong></h6>
						<p className="mb-0" style={{fontSize:"12px"}}>User who purchased merchant membership / own store credits will be displayed and managed here.</p>
					</div>

				</div>
			</div>
		</section>
	);

};

export default MembershipPage;