import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

// Hooks
import { useAuthContext } from '../hooks/useAuthContext';

const ExchangePage = () => {

	const { loggedInUser } = useAuthContext();

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
	  const response = await fetch('https://api.booster.my/user/'+loggedInUser.user.username, {
		method: 'GET',
		headers: {
		  'Content-Type': 'application/json',
		  'Authorization': `Bearer ${loggedInUser.token}`
		}
	  });
	  const json = await response.json();
	  if (response.ok) {
		if (json.length === 1) {
		  // Expect only 1 result
		  console.log(json[0]);
		  setUserData(json[0]);
		} else {
		  console.log("Invalid user data: More than 1 result.");
		}
	  }
	};

	const handleSubmit = async (e) => {
        e.preventDefault();

		fetchUserData();
    };

	useEffect(() => {
		if (loggedInUser) {
		  fetchUserData();
		}
	  }, [loggedInUser]);

	return (
		<section className="section-preset negative-margin">
			<div className="container">
				<div className="row">
					<div className="col-12 p-1">
						<h4 className="text-white"><strong>Exchange</strong></h4>
						<div className="data-box-white">
							<h6 className="mb-0">Coming Soon</h6>
						</div>
					</div>
				</div>


			</div>
		</section>
	);

};

export default ExchangePage;