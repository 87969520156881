import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

// Hooks
import { useLogout } from '../hooks/useLogout';
import { useAuthContext } from '../hooks/useAuthContext';

const Profile = () => {

	const { loggedInUser } = useAuthContext();
	const { logout } = useLogout();
	const handleLogout = () => {
	  logout();
	  window.location.href= "/login";
	}

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
	  const response = await fetch('https://api.booster.my/user/'+loggedInUser.user.username, {
		method: 'GET',
		headers: {
		  'Content-Type': 'application/json',
		  'Authorization': `Bearer ${loggedInUser.token}`
		}
	  });
	  const json = await response.json();
	  if (response.ok) {
		if (json.length === 1) {
		  // Expect only 1 result
		  console.log(json[0]);
		  setUserData(json[0]);
		} else {
		  console.log("Invalid user data: More than 1 result.");
		}
	  }
	  };

	  useEffect(() => {
		if (loggedInUser) {
		  fetchUserData();
		}
	  }, [loggedInUser]);

	return (
		<section className="section-preset negative-margin">
			<div className="container">

				<div className="row">
					<div className="col-12 p-1">
						<h4 className="text-white"><strong>Profile</strong></h4>
						<div className="data-box-white">
							<h5 className="brand-primary mb-1">{userData && userData.username}</h5>
							<p className="mb-1">Referred By <span className="brand-primary">{userData && userData.referrer}</span></p>
						</div>
					</div>
				</div>

				<div className="row mt-5">
					<div className="col-12 p-1">
						<h6 className=""><strong>Account Security</strong></h6>
						<div className="data-box-white">
							<h6 className="mb-0">Password: ********</h6>
							<button disabled className="btn action-button mt-2" onClick={(e) => {
							}}>
								<p className="mb-0" style={{fontSize:"14px"}}><i className="fa-solid fa-pen-to-square mr-2"></i><strong>Change</strong></p>
							</button>
						</div>
					</div>
				</div>

				<div className="row mt-5">
					<div className="col-12 p-1">
						<button className="btn logout-button w-100 mt-2" onClick={(e) => {
							handleLogout();
						}}>
							<p className="mb-0" style={{fontSize:"14px"}}><i className="fa-solid fa-left-from-bracket mr-2"></i><strong>Log Out</strong></p>
						</button>
					</div>
				</div>
			</div>
		</section>
	);

};

export default Profile;