// Dependencies
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation, FreeMode } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'


// import MainLogo from './assets/img/logo.png';
import PromoCoverImg from './assets/img/booster-cover.jpg';

// Pages
import RegisterPage from './pages/register';
import LoginPage from './pages/login';
import TeamPage from './pages/team';
import BonusPage from './pages/bonus';
import ProfilePage from './pages/profile';
import EarnPage from './pages/earn';
import TransferPage from './pages/transfer';
import MembershipPage from './pages/membership';
import MerchantPage from './pages/merchant';
import VoucherPage from './pages/voucher';
import ExchangePage from './pages/exchange';


// Hooks
import { useAuthContext } from './hooks/useAuthContext';

SwiperCore.use([Autoplay, Pagination, Navigation, FreeMode]);


function App() {

  // Set state for blockchain data
  // const dispatch = useDispatch();
  // const blockchain = useSelector((state) => state.blockchain);

  const { loggedInUser } = useAuthContext();

  const [btcMarketPrice, setBTCMarketPrice] = useState(0);
  const [btcPercentage, setBTCPercentage] = useState(0);
  const fetchBTCMarketPrice = async () => {
    const response = await fetch('https://api.booster.my/bybitapi/price/btc');
    const json = await response.json();
    if (response.ok) {
      console.log(json);
      setBTCMarketPrice(json.markPrice);
      setBTCPercentage(json.price24hPcnt);
    } else {
      console.log(json);
    }
	};

  const [userData, setUserData] = useState(null);
  const fetchUserData = async () => {
    const response = await fetch('https://api.booster.my/user/'+loggedInUser.user.username, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loggedInUser.token}`
      }
    });
    const json = await response.json();
    if (response.ok) {
      if (json.length === 1) {
        // Expect only 1 result
        console.log(json[0]);
        setUserData(json[0]);
      } else {
        console.log("Invalid user data: More than 1 result.");
      }
    }
	};

  const [userByBitBalance, setUserBybitBalance] = useState(null);
  const fetchUserBybitBalance = async () => {
    const response = await fetch('https://api.booster.my/bybitapi/user/balance', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${loggedInUser.token}`
      }
    });
    const json = await response.json();
    if (response.ok) {
      console.log("User Unified Account Data:", json);
      console.log("User Total Unified Wallet Balance:", json.data.result.list[0].totalWalletBalance);
      setUserBybitBalance(json.data.result.list[0].totalWalletBalance);
    }
	};

  useEffect(() => {
    if (loggedInUser) {
      fetchUserData();
      // fetchUserBybitBalance();
    }
  }, [loggedInUser]);

  // useEffect(() => {
  //   fetchBTCMarketPrice();
  //   const intervalId = setInterval(fetchBTCMarketPrice, 10000);
  //   return () => clearInterval(intervalId);
  // }, []);


  return (
    <div className="d-flex justify-content-center">
      <div className="mobile-view">
        <Router>

          <section className="header">
            <div className="container">
              {!loggedInUser ? (
              <div className="row">
                <div className="col-12 p-1">
                  <h6 className="text-white mb-0">Welcome to Booster App</h6>
                </div>
              </div>
              ):(
                <div className="row">
                  <div className="col-10 p-1">
                    <h6 className="text-white mb-0">Welcome, {loggedInUser.user.username}</h6>
                  </div>
                  <div className="col-2 p-1 text-right">
                    <NavLink to="/profile">
                      <h5 className="text-white mb-0"><i className="fa-solid fa-gear"></i></h5>
                    </NavLink>
                  </div>
                </div>
              )}

            </div>
          </section>

          <Routes>
            <Route
              path="/"
              element={loggedInUser ?
                <section className="section-preset negative-margin">
                  <div className="container">

                    <div className="row">

                      {/* <div className="col-12 p-1">
                        <div className="data-box-white">
                          <h6 className="text-gray mb-0"><strong>Account Balance</strong></h6>
                          <p className="balance-text mb-0">{userData && parseFloat(userData.cashWallet).toFixed(2)} <span className="text-gray" style={{fontSize:"16px"}}>AUSD</span></p>
                          <button className="btn action-button mt-2" onClick={(e) => {
                          }}>
                            <p className="mb-0" style={{fontSize:"14px"}}><strong>+ Top-up</strong></p>
                          </button>
                        </div>
                      </div> */}

                      <div className="col-12 p-1">
                        <h4 className="text-white"><strong>Explore</strong></h4>
                        <div className="data-box-white">
                          <div className="row">
                            <div className="col-4 text-center my-2">
                              <NavLink to="/membership">
                                <i className="menu-icon fa-duotone fa-solid fa-credit-card"></i>
                                <p className="text-gray mt-2 mb-2" style={{fontSize:"11px"}}>Membership</p>
                              </NavLink>
                            </div>
                            <div className="col-4 text-center my-2">
                              <NavLink to="/merchant">
                                <i className="menu-icon fa-duotone fa-solid fa-shop"></i>
                                <p className="text-gray mt-2 mb-2" style={{fontSize:"11px"}}>Merchant</p>
                              </NavLink>
                            </div>
                            <div className="col-4 text-center my-2">
                              <NavLink to="/voucher">
                                <i className="menu-icon fa-sharp-duotone fa-solid fa-ticket"></i>
                                <p className="text-gray mt-2 mb-2" style={{fontSize:"11px"}}>Voucher</p>
                              </NavLink>
                            </div>
                            <div className="col-4 text-center my-2">
                              <NavLink to="/exchange">
                                <i className="menu-icon fa-sharp fa-solid fa-right-left"></i>
                                <p className="text-gray mt-2 mb-0" style={{fontSize:"11px"}}>Exchange</p>
                              </NavLink>
                            </div>
                            <div className="col-4 text-center my-2">
                              <NavLink to="/earn">
                                <i className="menu-icon fa-solid fa-chart-mixed-up-circle-dollar"></i>
                                <p className="text-gray mt-2 mb-0" style={{fontSize:"11px"}}>Earn</p>
                              </NavLink>
                            </div>
                            <div className="col-4 text-center my-2">
                              <NavLink to="/transfer">
                                <i class="menu-icon fa-sharp fa-regular fa-money-bill-transfer"></i>
                                <p className="text-gray mt-2 mb-0" style={{fontSize:"11px"}}>Transfer</p>
                              </NavLink>
                            </div>

                          </div>
                        </div>
                      </div>

                    </div>

                    <div className="row mt-3">
                      <div className="col-12">
                        <h6 className=""><strong>Trending New Stores</strong></h6>
                      </div>
                      <div className="col-12">
                        <div className="row">
                        <Swiper
                          loop
                          freeMode={true}
                          grabCursor={true}
                          slidesPerView={2}
                          spaceBetween={5}
                          speed={2000}
                          autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                          }}
                        >
                          <SwiperSlide><img class="promo-cover-img" src={PromoCoverImg} alt="Promotion Thumnbail"></img></SwiperSlide>
                          <SwiperSlide><img class="promo-cover-img" src={PromoCoverImg} alt="Promotion Thumnbail"></img></SwiperSlide>
                          <SwiperSlide><img class="promo-cover-img" src={PromoCoverImg} alt="Promotion Thumnbail"></img></SwiperSlide>
                          <SwiperSlide><img class="promo-cover-img" src={PromoCoverImg} alt="Promotion Thumnbail"></img></SwiperSlide>
                          <SwiperSlide><img class="promo-cover-img" src={PromoCoverImg} alt="Promotion Thumnbail"></img></SwiperSlide>
                          <SwiperSlide><img class="promo-cover-img" src={PromoCoverImg} alt="Promotion Thumnbail"></img></SwiperSlide>
                        </Swiper>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-12">
                        <h6 className=""><strong>Product & Services</strong></h6>
                      </div>
                    </div>

                  </div>
                </section>
              :
              <Navigate to="/login" />
            }/>
            <Route path="/login" element={!loggedInUser ? <LoginPage/> : <Navigate to="/" />}/>
            <Route path="/register" element={!loggedInUser ? <RegisterPage/> : <Navigate to="/" />}/>
            <Route path="/team" element={loggedInUser ? <TeamPage/> : <Navigate to="/login" />}/>
            <Route path="/bonus" element={loggedInUser ? <BonusPage/> : <Navigate to="/login" />}/>
            <Route path="/profile" element={loggedInUser ? <ProfilePage/> : <Navigate to="/login" />}/>
            <Route path="/earn" element={loggedInUser ? <EarnPage/> : <Navigate to="/login" />}/>
            <Route path="/transfer" element={loggedInUser ? <TransferPage/> : <Navigate to="/login" />}/>
            <Route path="/membership" element={loggedInUser ? <MembershipPage/> : <Navigate to="/login" />}/>
            <Route path="/merchant" element={loggedInUser ? <MerchantPage/> : <Navigate to="/login" />}/>
            <Route path="/voucher" element={loggedInUser ? <VoucherPage/> : <Navigate to="/login" />}/>
            <Route path="/exchange" element={loggedInUser ? <ExchangePage/> : <Navigate to="/login" />}/>
          </Routes>
        </Router>

      </div>
    </div>
  );
}

export default App;