import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';

import { useLogin } from '../hooks/useLogin';

const Login = () => {

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const {login, error, isLoading} = useLogin();

	const handleSubmit = async (e) => {
		e.preventDefault();
		await login(username, password);
		// console.log("Login");
	};

	useEffect(() => {

	}, []);

	return (
		<section className="section-preset negative-margin">
			<div className="container form-page-padding">
				<div className="row">
					<div className="col-12 p-1">
						<div className="data-box-white">
							<h5 className="brand-primary"><strong>Login</strong></h5>
							<form className="w-100" onSubmit={handleSubmit}>
								<div className="mb-2">
									<input
										type="text"
										placeholder='Username'
										autoComplete='off'
										name='username'
										className='form-control'
										onChange={(e) => setUsername(e.target.value)}
									/>
								</div>
								<div className="mb-2">
									<input
										type="password"
										placeholder='Password'
										name='password'
										className='form-control rounded-0'
										onChange={(e) => setPassword(e.target.value)}
									/>
								</div>
								<button disabled={username == '' || password == ''} type="submit" className="btn main-button mt-3 w-100">
									<p className="text-white mb-0"><strong>Login</strong></p>
								</button>
								{error && <div className="error">{error}</div>}
							</form>
							<NavLink to="/register">
								<p className="mt-3 mb-2">Not registered yet?</p>
							</NavLink>
						</div>
					</div>
				</div>
			</div>
		</section>
	);

};

export default Login;