import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

// Hooks
import { useAuthContext } from '../hooks/useAuthContext';

// Images
import Rome13 from '../assets/img/merchant/rome13.jpg';

const MerchantPage = () => {

	const { loggedInUser } = useAuthContext();

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
	  const response = await fetch('https://api.booster.my/user/'+loggedInUser.user.username, {
		method: 'GET',
		headers: {
		  'Content-Type': 'application/json',
		  'Authorization': `Bearer ${loggedInUser.token}`
		}
	  });
	  const json = await response.json();
	  if (response.ok) {
		if (json.length === 1) {
		  // Expect only 1 result
		  console.log(json[0]);
		  setUserData(json[0]);
		} else {
		  console.log("Invalid user data: More than 1 result.");
		}
	  }
	};

	const handleSubmit = async (e) => {
        e.preventDefault();

		fetchUserData();
    };

	useEffect(() => {
		if (loggedInUser) {
		  fetchUserData();
		}
	  }, [loggedInUser]);

	return (
		<section className="section-preset negative-margin">
			<div className="container">
				<div className="row">

					<div className="col-12 p-1">
						<div className="merchant-cover-container">
							<img className="merchant-cover" src={Rome13}></img>
						</div>
					</div>

					<div className="col-12 p-1">
						<div className="data-box-white mt-2">
							<h5 className="mb-0"><strong>ROME XIII Bukit Jalil</strong></h5>
							<div className="d-flex justify-content-left">
								{/* <span className="yellow"><i className="fa-sharp fa-solid fa-star"></i></span>
								<span className={userData && userData.rank >= 2 ? "yellow" : "gray"}><i className="fa-sharp fa-solid fa-star"></i></span>
								<span className={userData && userData.rank >= 3 ? "yellow" : "gray"}><i className="fa-sharp fa-solid fa-star"></i></span>
								<span className={userData && userData.rank >= 4 ? "yellow" : "gray"}><i className="fa-sharp fa-solid fa-star"></i></span>
								<span className={userData && userData.rank >= 5 ? "yellow" : "gray"}><i className="fa-sharp fa-solid fa-star"></i></span> */}
								<span className="yellow"><i className="fa-sharp fa-solid fa-star"></i></span>
								<span className="yellow"><i className="fa-sharp fa-solid fa-star"></i></span>
								<span className="yellow"><i className="fa-sharp fa-solid fa-star"></i></span>
								<span className="yellow"><i className="fa-sharp fa-solid fa-star"></i></span>
								<span className="yellow"><i className="fa-sharp fa-solid fa-star"></i></span>
							</div>
							<p className="mb-0" style={{fontSize:"12px"}}>Welcome to ROME XIII, your new favorite live band bar at Aurora Place Bukit Jalil! Enjoy electrifying performances from top local bands in a warm, inviting atmosphere. With a wide selection of drinks from craft beers to wine and whiskey, ROME XIII is the perfect spot to relax, chill, and have fun.</p>
							<button disabled className="btn action-button mt-2 mr-2" onClick={(e) => {
							}}>
								<p className="mb-0" style={{fontSize:"14px"}}><strong>Contact Us</strong></p>
							</button>
							<hr></hr>
							<p className="brand-primary mb-0"><strong><i className="fa-sharp fa-solid fa-location-dot mr-1"></i>Location</strong></p>
							<p className="mb-0" style={{fontSize:"12px"}}>Unit C-02-04, Plaza Bukit Jalil (Aurora Place 1), Persiaran Jalil 1, 5700, Bandar, Bukit Jalil, 57000 Kuala Lumpur, Wilayah Persekutuan Kuala Lumpur</p>
							<p className="brand-primary mt-3 mb-0"><strong><i className="fa-sharp fa-solid fa-calendar-days mr-1"></i>Business Hours</strong></p>
							<p className="mb-0" style={{fontSize:"12px"}}>Monday - 08:00-22:00H</p>
							<p className="mb-0" style={{fontSize:"12px"}}>Tuesday - 08:00-22:00H</p>
							<p className="mb-0" style={{fontSize:"12px"}}>Wednesday - 08:00-22:00H</p>
							<p className="mb-0" style={{fontSize:"12px"}}>Thursday - 08:00-22:00H</p>
							<p className="mb-0" style={{fontSize:"12px"}}>Friday - 08:00-22:00H</p>
							<p className="mb-0" style={{fontSize:"12px"}}>Saturday - 08:00-22:00H</p>
							<p className="mb-0" style={{fontSize:"12px"}}>Sunday - Closed on alternate week</p>
						</div>
					</div>

					<div className="col-12 p-1 mt-5">
						<h6 className=""><strong>Purchase Store Credits</strong></h6>
						<p className="mb-0" style={{fontSize:"12px"}}>User can purchase store credits to spend on this merchant using their AUSD or the local e-currency.</p>

						<div className="data-box-white mt-3">
							<h5 className="brand-primary mb-0"><strong>Welcome Package</strong></h5>
							<p className="mb-0" style={{fontSize:"16px"}}><strong>Price: 300 eMYR</strong></p>
							<p className="balance-text mb-0">300<span className="balance-text profit-green"> +5%</span><span className="text-gray" style={{fontSize:"14px"}}> Store Credit</span></p>
							<button disabled className="btn action-button mt-2 mr-2" onClick={(e) => {
							}}>
								<p className="mb-0" style={{fontSize:"14px"}}><strong>Top-Up</strong></p>
							</button>
						</div>

						<div className="data-box-white mt-3">
							<h5 className="brand-primary mb-0"><strong>Super-Saver Package</strong></h5>
							<p className="mb-0" style={{fontSize:"16px"}}><strong>Price: 1000 eMYR</strong></p>
							<p className="balance-text mb-0">1000<span className="balance-text profit-green"> +10%</span><span className="text-gray" style={{fontSize:"14px"}}> Store Credit</span></p>
							<button disabled className="btn action-button mt-2 mr-2" onClick={(e) => {
							}}>
								<p className="mb-0" style={{fontSize:"14px"}}><strong>Top-Up</strong></p>
							</button>
						</div>

						<div className="data-box-white mt-3">
							<h5 className="brand-primary mb-0"><strong>Premium Package</strong></h5>
							<p className="mb-0" style={{fontSize:"16px"}}><strong>Price: 3000 eMYR</strong></p>
							<p className="balance-text mb-0">3000<span className="balance-text profit-green"> +15%</span><span className="text-gray" style={{fontSize:"14px"}}> Store Credit</span></p>
							<button disabled className="btn action-button mt-2 mr-2" onClick={(e) => {
							}}>
								<p className="mb-0" style={{fontSize:"14px"}}><strong>Top-Up</strong></p>
							</button>
						</div>
					</div>

				</div>
			</div>
		</section>
	);

};

export default MerchantPage;