import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MiddleEllipsis from "react-middle-ellipsis";

// Hooks
import { useAuthContext } from '../hooks/useAuthContext';

const EarnPage = () => {

	const { loggedInUser } = useAuthContext();

	const queryParameters = new URLSearchParams(window.location.search);
	var referrerWallet = queryParameters.get("referral");

	const [copied, setCopied] = useState(false);
	const [copyText, setCopyText] = useState("");
	const copyReferral = () => {    
	  setCopied(true);
	  setCopyText("Copied");
	  setTimeout(() => setCopied(false), 3000);
	}

	const [userData, setUserData] = useState(null);
	const fetchUserData = async () => {
	  const response = await fetch('https://api.booster.my/user/'+loggedInUser.user.username, {
		method: 'GET',
		headers: {
		  'Content-Type': 'application/json',
		  'Authorization': `Bearer ${loggedInUser.token}`
		}
	  });
	  const json = await response.json();
	  if (response.ok) {
		if (json.length === 1) {
		  // Expect only 1 result
		  console.log(json[0]);
		  setUserData(json[0]);
		} else {
		  console.log("Invalid user data: More than 1 result.");
		}
	  }
	};

	const [downlinesData, setDownlinesData] = useState(null);
	const fetchDownlinesData = async () => {
		const response = await fetch('https://api.booster.my/user/downline/'+loggedInUser.user.username, {
			method: 'GET',
			headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${loggedInUser.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			console.log(json);
			setDownlinesData(json);
		}
	};

	useEffect(() => {
		if (loggedInUser) {
			fetchUserData();
			fetchDownlinesData();
		}
	}, [loggedInUser]);

	useEffect(() => {
		console.log("Referrer Wallet from URL:", referrerWallet);
	}, []);

	return (
		<section className="section-preset negative-margin">
			<div className="container">

				<div className="row">
					<div className="col-12 p-1">
						<h4 className="text-white"><strong>Booster Earn</strong></h4>

						<div className="data-box-white mt-3">
							<h6 className="brand-primary mb-0"><strong>My Total Earnings</strong></h6>
							<p className="balance-text profit-green mb-0">{userData && parseFloat(userData.cashWallet).toFixed(2)} <span className="text-gray" style={{fontSize:"16px"}}>AUSD</span></p>
							<button disabled className="btn action-button mt-2 mr-2" onClick={(e) => {
							}}>
								<p className="mb-0" style={{fontSize:"14px"}}><strong>Claim</strong></p>
							</button>
						</div>
					</div>

					<div className="col-12 p-1 mt-5">
						<h6 className=""><strong>Flexible Saving Plan</strong></h6>

						<div className="data-box-white mt-3">
							<h6 className="brand-primary mb-0"><strong>Save & Earn</strong></h6>
							<p className="mb-0">Up to <span className="bonus-text profit-green">8% p.a.</span> in AUSD, without locking period, transfer-out anytime. Booster earnings will be distributed on a daily basis.</p>
						</div>

						<div className="data-box-white mt-3">
							<h6 className="brand-primary mb-0"><strong>My Flexible Saving</strong></h6>
							<p className="balance-text mb-0">{userData && parseFloat(userData.cashWallet).toFixed(2)} <span className="text-gray" style={{fontSize:"16px"}}>AUSD</span></p>
							<button disabled className="btn action-button mt-2 mr-2" onClick={(e) => {
							}}>
								<p className="mb-0" style={{fontSize:"14px"}}><strong>Transfer-In</strong></p>
							</button>
							<button disabled className="btn action-button mt-2 mr-2" onClick={(e) => {
							}}>
								<p className="mb-0" style={{fontSize:"14px"}}><strong>Transfer-Out</strong></p>
							</button>
						</div>

					</div>

					<div className="col-12 p-1 mt-5">
						<h6 className=""><strong>Referral Program</strong></h6>

						<div className="data-box-white mt-3">
							<h6 className="brand-primary mb-0"><strong>Refer & Earn</strong></h6>
							<p className="mb-0">Invite your friends and family to register as Booster member and earn up to <span className="bonus-text profit-green">5%</span> in AUSD from every top-up transaction.</p>
							<hr></hr>
							<p className="mt-3 mb-0" style={{fontSize:"14px"}}>Commission by tiers:</p>
							<p className="mb-0"><strong>Silver Member = <span className="profit-green">1.5%</span></strong> per top-up</p>
							<p className="mb-0"><strong>Gold Member = <span className="profit-green">3.0%</span></strong> per top-up</p>
							<p className="mb-0"><strong>Black Premier = <span className="profit-green">5.0%</span></strong> per top-up</p>
						</div>

						<div className="data-box-white mt-3">
							<h6 className="brand-primary mb-0"><strong>My Referral Link</strong></h6>
							<p className="text-gray mb-0">https://booster.my/register?referral={loggedInUser.user.username}</p>
							<CopyToClipboard 
								text={"https://booster.my/register?referral="+loggedInUser.user.username}
								onCopy={() => copyReferral()}>
								<button className="btn action-button mt-2" onClick={(e) => {
								}}>
									{!copied ? (
										<p className="mb-0"><strong><i className="fa-sharp fa-solid fa-copy mr-2"></i>Copy</strong></p>
									):(
										<p className="mb-0"><strong><i className="fa-sharp fa-solid fa-copy mr-2"></i>{copyText}</strong></p>
									)}
								</button>
							</CopyToClipboard>
						</div>

					</div>

					<div className="col-12 p-1 mt-3">
						<h6 className=""><strong>My Referrals</strong></h6>

						<div className="data-box-white mt-3">

							{downlinesData && downlinesData.length > 0 ? (
								<>
									{downlinesData && downlinesData.map((teamUser, index) => (
										<div key={index}>
											<h6 className="brand-primary mb-0"><strong>{teamUser.username}</strong></h6>
											<p className="mb-0">Total top-up: <span className="bonus-text profit-green">{parseFloat(0).toFixed(2)}</span> AUSD</p>
											<hr></hr>
										</div>
									))}
									<p className="text-gray mb-0">Total Referrals: {downlinesData.length}</p>
								</>
							):(
								<p className="text-gray mb-0">No referral is found for this account. Invite your friend to join as Booster member and start earning!</p>
							)}
						</div>

					</div>
				</div>

			</div>
		</section>
	);

};

export default EarnPage;